.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.a1
{
    width: 50%" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100";
}
.a2
{
    width: 20%" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100";
}
.a3
{
    width: 40%" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100";
}
.a4
{
    width: 80%" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100";
}
.a5
{
    width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100";
}
.a6
{
    width: 25rem;
}
.a7
{
    width: 60%" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100";
}
.toggled {
    overflow: visible;
    width: 6.5rem!important;
}
.toggled1 {
    width: 0!important;
    overflow: hidden;
}