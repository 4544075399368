body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#wrapper, .wrapper {
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#change-alert {
  text-align: center;
  width: 100%;
  bottom: 50px;
  opacity: 0.9;
}

ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.timeline > li:before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #d4d9df;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}

ul.timeline > li.primary:before {
  border: 3px solid #1cc88a !important;
  background: #1cc88a !important;
}

ul.timeline > li.secondary:before {
  border: 3px solid #d4d9df !important;
  background: #d4d9df !important;
}

ul.timeline > li.danger:before {
  border: 3px solid #dc3545 !important;
  background: #dc3545 !important;
}

